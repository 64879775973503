import { render, staticRenderFns } from "./AllWalletsTransactionsLogs.vue?vue&type=template&id=674c8b5b&"
import script from "./AllWalletsTransactionsLogs.vue?vue&type=script&lang=js&"
export * from "./AllWalletsTransactionsLogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports