<template>
  <main class="table-font ">
    <h1>All Wallet Transactions Logs</h1>
    <div class="flex flex-col">
      <div
        class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
      >
        <div class="">
          <div class="flex time-filter">
            <span class="mr-4">From: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.start_date"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startTime"
            />
          </div>
          <div class="flex time-filter mt-2">
            <span class="mr-9">To: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.end_date"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endTime"
            />
          </div>
          <div class="flex mt-3">
            <button
              @click="getTransactionLogs"
              class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Download Records
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flex items-center mt-5 gap-5">
      <date-picker
        :disabled-date="
          (date) => date > new Date() || date < new Date('2018-01-01')
        "
        type="datetime"
        v-model="dateRange"
        range
      ></date-picker>
      <button
        @click="getTransactionLogs"
        :disabled="isLoading"
        :class="isLoading ? 'opacity-50 cursor-not-allowed' : ''"
        class="items-center justify-center text-sm font-medium transition-colors bg-blue-400 text-white hover:bg-blue-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground px-3 rounded-md hidden h-8 lg:flex"
        type="button"
        aria-haspopup="menu"
        aria-expanded="false"
        data-state="closed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-4 h-4 mr-3"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="21" x2="14" y1="4" y2="4"></line>
          <line x1="10" x2="3" y1="4" y2="4"></line>
          <line x1="21" x2="12" y1="12" y2="12"></line>
          <line x1="8" x2="3" y1="12" y2="12"></line>
          <line x1="21" x2="16" y1="20" y2="20"></line>
          <line x1="12" x2="3" y1="20" y2="20"></line>
          <line x1="14" x2="14" y1="2" y2="6"></line>
          <line x1="8" x2="8" y1="10" y2="14"></line>
          <line x1="16" x2="16" y1="18" y2="22"></line>
        </svg>
        Download
      </button>
    </div> -->
  </main>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Loans",
  data() {
    return {
      moment,
      dateRange: [],
      isLoading: false,
      dateData: null,
    };
  },
  components: {
    DatePicker,
  },
  created() {
    const d = new Date();
    this.dateData = {
      start_date: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
  },

  methods: {
    async getTransactionLogs() {
      this.isLoading = true;
      try {
        let payload = { date: this.dateData, isDownload: true };

        // for (let key in query) {
        //   let value = query[key];
        //   if ((value === null || value === undefined) && key === "walletId") {
        //     value = "null";
        //   }
        //   if (value !== null && value !== undefined) {
        //     params.append(key, value);
        //   }
        // }

        let res = await this.$store.dispatch(
          "GET_ALL_WALLETS_TRANSACTIONS_LOGS",
          payload
        );
        alert(res.message);
        return res;
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.dateRange = [
      moment()
        .startOf("day")
        .toDate(),
      moment().toDate(),
    ];
  },
};
</script>
